<style lang="scss" scoped>
.page-statistics-vehicles {}
</style>
<template>
    <div class="page-statistics-vehicles">
        <div class="mrgb20">
            <el-button-group class="mrgr5 mrgb5">
                <el-button type="primary" size="small" v-for="item in selectTimes" :key="item"
                    :plain="curSelectTime != item" @click="selectRange(item)">{{ item === 1 ? "昨日" : "近" + item + "日" }}</el-button>
                <el-button type="primary" size="small" :plain="curSelectTime > 0" @click="selectRange(-1)">自定义时间段</el-button>
            </el-button-group>
            <span v-show="isShowSelectTime">
                <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="small"
                :default-time="['00:00:00', '23:59:59']" @change="getSearchData" :clearable="false"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                align="right">
                </el-date-picker>
                <el-button class="mrgr5 mrgb5" type="primary" size="small" @click="getSearchData()"><i class="fa fa-search"></i> 查询</el-button>
            </span>
        </div>
        <el-row :gutter="15">
            <el-col :xs="24" class="mrgb20">
                <el-card>
                    <span slot="header">
                        车辆统计
                        <el-tooltip effect="dark" content="投放车辆总数：归属于该运营区的全部车辆" placement="top">
                            <div slot="content">
                                投放车辆总数：归属于该运营区的全部车辆<br />
                                可用车辆数：可以骑行的车辆，总投放数-非可用车辆数<br />
                                可用车辆占比：可用车辆/总投放车
                            </div>
                            <el-tag class="fr" type="info" size="small"><i class="el-icon-view f12"></i> 查看注释</el-tag>
                        </el-tooltip>
                        <span class="db"></span>
                    </span>
                    <echart id="vehicleCommonEchart" title="" width="100%" height="520px" :option="vehicleCommonOptions"></echart>
                </el-card>
            </el-col>
            <el-col :xs="24" :lg="12" class="mrgb20" v-for="(item, index) in otherOptions" :key="'vehicleEchartKey' + index">
                <el-card>
                    <span slot="header">
                        {{ otherLegends[index] }}
                        <el-tooltip effect="dark" :content="otherTips[index]" placement="top">
                            <el-tag class="fr" type="info" size="small"><i class="el-icon-view f12"></i> 查看注释</el-tag>
                        </el-tooltip>
                    </span>
                    <echart :id="'vehicleEchart' + index" title="" width="100%" height="520px" :option="item"></echart>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import echart from "@/components/Echarts/Index"
import moment from "moment"
export default {
    name: "pageStatisticsVehicle",
    components: { echart },
    data() {
        return {
            listQuery: {
                area: "",
                beginDate: "",
                endDate: "",
                /* type: 0,
                keywords: null,
                page: 1,
                size: window.$common.appPageSize */
            },
            selectTimes: [1, 3, 7, 10, 15, 30],
            curSelectTime: null,
            isShowSelectTime: false,
            selectedTime: [],
            reportData: {},
            vehicleCommonOptions: {}, // 投放总数，可用车数量，占比
            otherLegends: ["报修车辆", "低电量车辆", "电单车DAB", "换电量", "挪车量", "维修量"],
            otherTips: ["已报修得车辆数", "低电量的车辆数", "单日活跃车辆数", "换电的车辆数", "挪车量的车辆数", "维修的车辆数"],
            otherOptions: []
        }
    },
    watch: {
      "$store.getters.serviceArea"(v) {
          this.mxIsSameActiveTabWithRoute(() => {
              this.getSearchData()
          })
      }
    },
    mounted() {
        this.curSelectTime = this.selectTimes[2]
        for (let i in this.otherLegends) {
            this.otherOptions.push({})
        }
        this.getSearchData()
    },
    methods: {
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] ? moment(timeArr[0]).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss") : ""
            this.listQuery.endDate = timeArr[1] ? moment(timeArr[1]).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss") : ""
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.curSelectTime > 0) {
            let timeArr = this.setPickerTime(null, this.curSelectTime, "days")
            this.setQueryTime(timeArr)
            } else {
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
            }
        },
        getSearchData() {
            if (this.curSelectTime < 0 && (!this.selectedTime[0] || !this.selectedTime[1])) {
                this.warningMsg("请选择日期范围!")
                return false
            }
            window.$common.fullLoading()
            this.getDataList()
        },
        async getDataList() {
            this.setSelectedTime()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            // let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
            // this.listQuery.size = days + 1
            await funReport.GetItems(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.reportData = response
                // 投放车辆，可用车数量，可用车占比
                this.setVehicleCommonOptions()
                // 保修，低电量，wab
                let options = []
                for (let i in this.otherLegends) {
                    options[i] = this.setOtherOptions(this.otherLegends[i])
                }
                this.otherOptions = options
            })
        },
        setVehicleCommonOptions() {
            let legendData = ["投放车辆总数", "可用车辆数", "可用车辆占比"]
            let xAxisData = []
            let series = []
            let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
            // 横坐标
            for (let i = 0; i <= days; i++) {
                let time = moment(this.listQuery.beginDate).add(i, "days")
                xAxisData.push(moment(time).format("YYYY-MM-DD"))
            }
            for (let i = 0; i < legendData.length; i++) {
                let temp = {
                    name: legendData[i],
                    type: "bar",
                    // smooth: false,
                    // stack: "basic",
                    // areaStyle: {},
                    barWidth: "30%",
                    barMaxWidth: "20px",
                    // barMinHeight: "10",
                    data: []
                }
                for (let k = 0; k < xAxisData.length; k++) {
                    let vehicleItem = this.reportData.find(x => {
                        return moment(xAxisData[k]).isSame(x.date) 
                    })
                    let item = 0
                    // 有车辆数据
                    if (vehicleItem && vehicleItem.vehicle) {
                        let veh = vehicleItem.vehicle
                        switch(i) {
                            case 0: item = veh.totalVehicleCount || 0; break;
                            case 1: 
                                item = veh.availableVehicleCount || 0; 
                                temp.markPoint = {
                                    symbolSize: 40,
                                    data: [
                                        { type: "max", name: "最大值" },
                                        { type: "min", name: "最小值" }
                                    ]
                                }
                                temp.barGap = "-100%"
                                temp.itemStyle = {
                                    normal: {
                                        barBorderRadius: [10, 10, 0, 0]
                                    }
                                }
                                break;
                            case 2: 
                                // 可用车辆占比
                                if (veh.totalVehicleCount) {
                                    item = (veh.availableVehicleCount || 0) / veh.totalVehicleCount;
                                    item = window.$util.toFixed(item, 4);
                                    item = window.$util.operationMulti(item, 100);
                                } else {
                                    item = 0;
                                }
                                temp.type = "line"
                                temp.yAxisIndex = 1
                                break;
                        }
                    }
                    temp.data.push(item)
                }
                series.push(temp)
            }
            this.vehicleCommonOptions = {
                tooltip: {
                    trigger: "axis",
                    formatter: "{b0}<br/>{a0}：{c0}<br/>{a1}：{c1}<br/>{a2}：{c2}%"
                },
                legend: {
                    data: legendData
                },
                grid: {
                    containLabel:true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                dataZoom: {
                    show: true,
                    left: "6%",
                    width: "87%",
                    start: 0,
                    end: 100,
                    labelFormatter: function (value) {
                        let prev = moment(xAxisData[value]).format("YYYY")
                        let next = moment(xAxisData[value]).format("MM/DD")
                        return prev + "\n" + next;
                    }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    axisLabel: {
                        // rotate: 30,
                        // interval: 0,
                        showMinLabel: true,
                        showMaxLabel: true
                    },
                    data: xAxisData,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "#e8e8e8"
                        }
                    }
                },
                yAxis: [
                    {
                        type: "value",
                        name: "车辆数",
                        max: function (value) {
                            return Math.ceil(value.max * 1.2);
                        },
                        axisTick: {
                            
                        },
                        axisLabel: {
                            formatter: "{value}"
                        }
                    },
                    {
                        type: "value",
                        name: "占比",
                        max: 120,
                        axisLine: {
                            show: true
                        },
                        axisLabel: {
                            formatter: "{value}%"
                        }
                    }
                ],
                series: series
            }
        },
        setOtherOptions(title) {
            let legendData = [title]
            let xAxisData = []
            let series = []
            let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
            // 横坐标
            for (let i = 0; i <= days; i++) {
                let time = moment(this.listQuery.beginDate).add(i, "days")
                xAxisData.push(moment(time).format("YYYY-MM-DD"))
            }
            let rates = []
            for (let i = 0; i < legendData.length; i++) {
                let temp = {
                    name: legendData[i],
                    type: "bar",
                    // smooth: false,
                    // stack: "basic",
                    // areaStyle: {},
                    barWidth: "30%",
                    barMaxWidth: "20px",
                    // barMinHeight: "10",
                    markPoint: {
                        symbolSize: 40,
                        data: [
                            { type: "max", name: "最大值" },
                            { type: "min", name: "最小值" }
                        ]
                    },
                    markLine: {
                        label: {
                            position: "center"
                        },
                        data: [
                            { type: "average", name: "平均值" }
                        ]
                    },
                    data: []
                }
                for (let k = 0; k < xAxisData.length; k++) {
                    let vehicleItem = this.reportData.find(x => {
                        return moment(xAxisData[k]).isSame(x.date) 
                    })
                    let item = 0
                    let rate = 0
                    // 有车辆数据
                    if (vehicleItem && vehicleItem.vehicle) {
                        let veh = vehicleItem.vehicle
                        let opera = vehicleItem.operation
                        switch(i) {
                            case 0: 
                                // 可用车辆占比
                                if (veh.totalVehicleCount) {
                                    if (title === this.otherLegends[0]) {
                                        // 报修车辆
                                        item = veh.reportedVehicleCount || 0; 
                                    } else if (title === this.otherLegends[1]) {
                                        // 低电量车辆
                                        item = veh.lowBatteryVehicleCount || 0; 
                                    } else if (title === this.otherLegends[2]) {
                                        // dab车辆
                                        item = veh.dabVehicleCount || 0; 
                                    } else if (title === this.otherLegends[3]) {
                                        // 换电量
                                        item = opera.batterySwapCount || 0; 
                                    } else if (title === this.otherLegends[4]) {
                                        // 挪车量
                                        item = opera.moveCount || 0; 
                                    } else if (title === this.otherLegends[5]) {
                                        // 维修量
                                        item = opera.maintenanceCount || 0; 
                                    }
                                    if (item) {
                                        rate = item / veh.totalVehicleCount;
                                        rate = window.$util.toFixed(rate, 4);
                                        rate = window.$util.operationMulti(rate, 100);
                                    }
                                }
                                break;
                        }
                    }
                    if (rates[i]) {
                        rates[i].push(rate)
                    } else {
                        rates[i] = [rate]
                    }
                    temp.data.push(item)
                }
                series.push(temp)
            }
            let curOptions = {
                tooltip: {
                    trigger: "axis",
                    formatter: function(params) {
                        let backVal = ""
                        for (let i in params) {
                            let par = params[i]
                            backVal = `${par.name}<br/>${par.seriesName}：${par.data}<br/>占投放比例：${rates[i][par.dataIndex]}%`
                        }
                        return backVal
                    }
                },
                legend: {
                    data: legendData,

                },
                grid: {
                    containLabel:true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                dataZoom: {
                    show: true,
                    left: "10%",
                    width: "83%",
                    start: 0,
                    end: 100,
                    labelFormatter: function (value) {
                        let prev = moment(xAxisData[value]).format("YYYY")
                        let next = moment(xAxisData[value]).format("MM/DD")
                        return prev + "\n" + next;
                    }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    axisLabel: {
                        // rotate: 30,
                        // interval: 0,
                        showMinLabel: true,
                        showMaxLabel: true
                    },
                    data: xAxisData,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "#e8e8e8"
                        }
                    }
                },
                yAxis: [
                    {
                        type: "value",
                        axisTick: {},
                        axisLabel: {
                            formatter: "{value}"
                        }
                    }
                ],
                series: series
            }
            return curOptions
        },
        selectRange(time) {
            this.curSelectTime = time
            this.isShowSelectTime = time > 0 ? false : true
            this.selectedTime = time > 0 ? [] : this.selectedTime
            if (time > 0) {
                this.getSearchData()
            }
        }
    }
}
</script>